import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'
import { StyledLink } from '../common/link'

import { device  } from '../../utils/breakpoints'


import { RiMapPin2Fill } from 'react-icons/ri'
import { FaFacebook } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'

const StyledWrapper = styled(Wrapper) `
    background: ${({ theme }) => theme.colors.white};
    padding: 3rem 5vw;
    min-height: 100vh;
`

const StyledContainer = styled(Container) `
    gap: 4rem;
    justify-items: center;
    align-items: center;
`

//Top
const ContainerOnTopPlaces = styled.div `
    display: grid;
    justify-items: center;
    gap: 1rem;
`
const ContainerLogo = styled.div `
    width: 150px;
    height: 100%;
`
const StyledHeading = styled(Heading) `
    font-weight: 500;
    color: ${({ theme }) => theme.colors.green};
`
const StyledHeadingGrid = styled(Heading) `
    font-weight: 500;
    justify-items: center;
    display: grid;
    grid-template-columns: auto;
    gap: 0.7rem;

    @media ${device.tabletL} {
        grid-template-columns: repeat(3, auto);
    }
`

//Center
const ContainerPlaces = styled.div `
    display: grid;
    justify-items: center;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: auto;
    width: 100%;
    height: 100%;
    gap: 2rem;
    

    @media ${device.tabletL} {
        grid-template-columns: auto auto auto;
        grid-template-rows: auto;
        
    }
`

const ContainerPlace = styled.div `
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;

    .image-styles-places {
        display: grid;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 9;

        ::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background: #E8E8E8;    
            opacity: 0%;       
        }

        @media ${device.tabletL} {
            ::after {
                opacity: 45%;
            }
        }
    }

    @media ${device.tabletL} {
        :hover {
            border: 1px solid #2D6A4F;

            .image-styles-places {
            display: grid;
            width: 100%;
            height: 100%;
            position: relative;
            ::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                background: #E8E8E8;
                opacity: 0%;
            }
        }
        }
    }
`

const PlaceTextBottom = styled.div `
    position: absolute;
    bottom: 0px;
    z-index: 10;
    display: grid;
    width: 100%;
    height: 80px;
    background-color: rgba(255,255,255, 0.8);

 
`
const ContainerTextIcon = styled.div `
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    align-items: center;
`   

//Link

const StyledLinkPlace = styled(StyledLink) `
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 450px;

    @media ${device.tabletL} {
        max-width: 350px;
    }

`

//Bottom

const ContainerBottomChoosePlace = styled.div `
    display: grid;
    justify-items: center;

`
const ContainerBottomSocialMedia = styled.div `
    display: grid;
    gap: 1rem;
    justify-items: center;

    h6 {
        color: #2D6A4F;
    }
`

const LinkSM = styled.a `
    cursor: pointer;

    .icon-sm{
        color: #2D6A4F;
    }
    
    :hover {
        
    }
`

const ContainerBottomIconsSocialMedia = styled.div `
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 1rem;

`

export const ChoosePlace = () => {

    return (
        <StyledWrapper>
            <StyledContainer>
                <ContainerOnTopPlaces>
                    <ContainerLogo>
                        <StaticImage
                            src="../../images/logo.png"
                            alt="BodyMed Group Torun" 
                            title="BodyMed Group Torun" 
                            layout="fullWidth"
                            placeholder="dominantColor"
                            className="mage"
                            imgClassName="imageStyles"
                        />
                    </ContainerLogo>
                    <StyledHeadingGrid h1 as='h1'>
                        <span>Depilacja laserowa</span> 
                        <span>Modelowanie sylwetki</span> 
                        <span>Kosmetologia estetyczna</span>
                    </StyledHeadingGrid>
                    <StyledHeading h3 as='h3'>
                        Prosimy wybierz miasto:
                    </StyledHeading>
                </ContainerOnTopPlaces> 
                <ContainerPlaces>

                    <StyledLinkPlace to='/lodz'>
                        <ContainerPlace>
                            <StaticImage
                                src="../../images/lodz-wybierz-miasto.jpg"
                                alt="BodyMed Group Lodz" 
                                title="BodyMed Group Lodz" 
                                layout="fullWidth"
                                className="image-styles-places"
                                imgClassName="imageStyles"
                            />
                            <PlaceTextBottom>
                                <ContainerTextIcon>
                                    <RiMapPin2Fill color='#2D6A4F' size='24' />
                                    <Heading h4 as='h4'>Łódź</Heading>
                                </ContainerTextIcon>
                            </PlaceTextBottom>  
                        </ContainerPlace>
                    </StyledLinkPlace>

                    <StyledLinkPlace to='/torun'>
                        <ContainerPlace>
                            <StaticImage
                                src="../../images/torun-wybierz-miasto.jpg"
                                alt="BodyMed Group Torun" 
                                title="BodyMed Group Torun" 
                                layout="fullWidth"
                                className="image-styles-places"
                                imgClassName="imageStyles"
                            />
                            <PlaceTextBottom>
                                <ContainerTextIcon>
                                    <RiMapPin2Fill color='#2D6A4F' size='24' />
                                    <Heading h4 as='h4'>Toruń</Heading>
                                </ContainerTextIcon>
                            </PlaceTextBottom>  
                        </ContainerPlace>
                    </StyledLinkPlace>

                    <StyledLinkPlace to='/bydgoszcz'>
                        <ContainerPlace>
                            <StaticImage
                                src="../../images/bydgoszcz-wybierz-miasto.jpg"
                                alt="BodyMed Group Bydgoszcz" 
                                title="BodyMed Group Bydgoszcz" 
                                layout="fullWidth"
                                className="image-styles-places"
                                imgClassName="imageStyles"
                            />
                            <PlaceTextBottom>
                                <ContainerTextIcon>
                                    <RiMapPin2Fill color='#2D6A4F' size='24' />
                                    <Heading h4 as='h4'>Bydgoszcz</Heading>
                                </ContainerTextIcon>
                            </PlaceTextBottom>  
                        </ContainerPlace>
                    </StyledLinkPlace>

                </ContainerPlaces>

                <ContainerBottomChoosePlace>
                    <ContainerBottomSocialMedia>
                        <h6>Znajdź nas na:</h6>

                        <ContainerBottomIconsSocialMedia>
                            <LinkSM href='https://www.facebook.com/bodymedgroup'>
                                <FaFacebook class='icon-sm' size='24'/>
                            </LinkSM>
                            <LinkSM href='https://www.instagram.com/bodymedgroup/'>
                                <FiInstagram class='icon-sm' size='24'/>
                            </LinkSM>
                        </ContainerBottomIconsSocialMedia>
                    </ContainerBottomSocialMedia>
                </ContainerBottomChoosePlace>

            </StyledContainer>
        </StyledWrapper>
    )
}