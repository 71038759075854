import React from "react"
import Layout from '../components/layout'

import { ChoosePlace } from '../components/choosePlace'
import Seo from '../components/seo'

const IndexPage = () => {
  return (
    <Layout choosePlace>
      <Seo
        title='Wybierz miasto'
        description='BodyMed Group'
        url='https://bodymedgroup.pl'
      />
      <ChoosePlace />
    </Layout>
  )
}

export default IndexPage
